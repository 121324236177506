<template>
	<div class="page-content no-padding">
        <div class="flex flex-column align-items-center justify-content-center h-full pt-5 pb-5">
            <div>
                <p class="font-bold mb-4">Puedes cambiar los datos de la comunidad modificando los siguientes campos.</p>
                <label for="name" class="block text-900 font-medium mb-2">Nombre</label>
                <InputText v-model="v$.name.$model" id="name" type="text" class="w-full mb-2" :class="{'p-invalid': v$.name.$error}" required />
                <div v-for="error of v$.name.$errors" :key="error.$uid" class="mb-4">
                    <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                </div>

                <label for="domain" class="block text-900 font-medium mb-2">Dominio</label>
                <InputText v-model="domain" id="domain" type="text" class="w-full mb-4" readonly />

                <label for="cif" class="block text-900 font-medium mb-2">N.I.F.</label>
                <InputText v-model="cif" id="cif" type="text" class="w-full mb-4" readonly />

                <label for="street" class="block text-900 font-medium mb-2">Calle, avenida, etc.</label>
                <InputText v-model="v$.street.$model" id="street" type="text" class="w-full mb-2" :class="{'p-invalid': v$.street.$error}" required />
                <div v-for="error of v$.street.$errors" :key="error.$uid" class="mb-4">
                    <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                </div>

                <div class="flex flex-column lg:flex-row">
                    <div class="col-12 lg:col-6">
                        <label for="number" class="block text-900 font-medium mb-2">Número</label>
                        <InputText v-model="v$.number.$model" id="number" type="text" class="w-full mb-2" :class="{'p-invalid': v$.number.$error}" required />
                        <div v-for="error of v$.number.$errors" :key="error.$uid" class="mb-4">
                            <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                        </div>
                    </div>
                    <div class="col-12 lg:col-6">
                        <label for="other" class="block text-900 font-medium mb-2">Otros datos</label>
                        <InputText v-model="other" id="other" type="text" class="w-full mb-2" />
                    </div>
                </div>

                <div class="flex flex-column lg:flex-row">
                    <div class="col-12 lg:col-6">
                        <label for="pc" class="block text-900 font-medium mb-2">Código postal</label>
                        <InputText v-model.trim="v$.pc.$model" id="pc" type="text" class="w-full mb-2" :class="{'p-invalid': v$.pc.$error}" required />
                        <div v-for="error of v$.pc.$errors" :key="error.$uid" class="mb-4">
                            <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                        </div>
                    </div>
                    <div class="col-12 lg:col-6">
                        <label for="locality" class="block text-900 font-medium mb-2">Localidad</label>
                        <InputText v-model="v$.locality.$model" id="locality" type="text" class="w-full mb-2" :class="{'p-invalid': v$.locality.$error}" required />
                        <div v-for="error of v$.locality.$errors" :key="error.$uid" class="mb-4">
                            <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                        </div>
                    </div>
                </div>

                <label for="province" class="block text-900 font-medium mb-2">Provincia</label>
                <InputText v-model="v$.province.$model" id="province" type="text" class="w-full mb-2" :class="{'p-invalid': v$.province.$error}" required />
                <div v-for="error of v$.province.$errors" :key="error.$uid" class="mb-4">
                    <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                </div>

                <label for="bg" class="block text-900 font-medium mb-2 mt-4">Fondo de inicio de sesión (opcional)</label>
                <FileUpload name="bg" :customUpload="true" @uploader="fileChanged" @select="fileChanged" mode="advanced" :showUploadButton="false" chooseLabel="Reemplazar fondo" :accept="$appState.acceptedMimetypes" :fileLimit="1" />

                <label for="appbg" class="block text-900 font-medium mb-2 mt-4">Fondo de aplicación (opcional)</label>
                <FileUpload name="appbg" :customUpload="true" @uploader="fileChanged2" @select="fileChanged2" mode="advanced" :showUploadButton="false" chooseLabel="Reemplazar fondo" :accept="$appState.acceptedMimetypes" :fileLimit="1" />

                <label for="buttonType" class="block text-900 font-medium mt-4">Estilo de botón</label>
                <div class="grid no-height py-4 px-4">
                    <div class="col-12 md:col-6">
                        <div class="column-button category-button items-start mb-4" style="height: 155px;">
                            <img src="https://api.iconify.design/mdi/folder-text.svg?color=%236366F1" style="height: 1.6rem;" alt="">
                            <div class="button-wrapper">
                                <h5>Nombre de categoría</h5>
                                <h6>Descripción corta</h6>
                            </div>
                        </div>
                        <div>
                            <RadioButton inputId="buttonType1" name="buttonType" value="button-type-1" v-model="buttonType" />
                            <label for="buttonType1" class="ml-3">Tipo 1</label>
                        </div>
                    </div>
                    <div class="col-12 md:col-6">
                        <div class="column-button button-type-2 category-button items-start mb-4">
                            <img src="https://api.iconify.design/mdi/folder-text.svg?color=%236366F1" style="height: 3.5rem;" alt="">
                            <div class="button-wrapper">
                                <h5>Nombre de categoría</h5>
                                <h6>Descripción corta</h6>
                            </div>
                        </div>
                        <div>
                            <RadioButton inputId="buttonType2" name="buttonType" value="button-type-2" v-model="buttonType" />
                            <label for="buttonType2" class="ml-3">Tipo 2</label>
                        </div>
                    </div>
                </div>

                <div class="mb-4" v-if="updateError"><small class="p-error font-bold">{{ updateError }}</small></div>

                <Button label="Actualizar" icon="pi pi-check" class="w-full mt-4" @click="updateEstate" :disabled="canSend"></Button>

                <div class="mt-4" v-if="success"><small class="font-bold">Datos actualizados correctamente.</small></div>
            </div>
        </div>
    </div>
</template>

<script>
import RouteGuardService from '@/service/RouteGuardService.js';
import AuthService from '@/service/AuthService';
import EstateService from '@/service/EstateService';
import ErrorReportService from '@/service/ErrorReportService.js';
import useVuelidate from '@vuelidate/core';
import { required, minLength, maxLength, numeric } from '@vuelidate/validators';
import ValidationMessages from '@/validationMessages';

const compUrl = '/settings';

export default {
    beforeRouteEnter() {
        let goTo = RouteGuardService.isLoggedIn();

        if (goTo !== true) {
            window.sessionStorage.setItem('afterLogin', compUrl);
        }

        return goTo;
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            name: '',
            domain: '',
            cif: '',
            street: '',
            number: '',
            other: '',
            pc: '',
            locality: '',
            province: '',
            bg: null,
            appbg: null,
            buttonType: 'button-type-1',
            working: false,
            updateError: null,
            success: false
        };
    },
    authService: null,
    estateService: null,
    errorReportService: null,
    selectedFile: null,
    selectedFile2: null,
    created() {
        this.authService = new AuthService();
        this.estateService = new EstateService();
        this.errorReportService = new ErrorReportService();
        this.validationMessages = ValidationMessages;

        this.name = this.$appState.estate.name;
        this.domain = this.$appState.estate.domain;
        this.cif = this.$appState.estate.cif;
        this.street = this.$appState.estate.address?.street;
        this.number = this.$appState.estate.address?.number;
        this.other = this.$appState.estate.address?.other;
        this.pc = this.$appState.estate.address?.pc;
        this.locality = this.$appState.estate.address?.locality;
        this.province = this.$appState.estate.address?.province;
        this.buttonType = this.$appState.estate.extra_data?.button_type ?? 'button-type-1';
    },
    validations() {
        return {
            name: { required },
            street: { required, minLength: minLength(3) },
            number: { required },
            pc: { required, numeric, minLength: minLength(5), maxLength: maxLength(5) },
            locality: { required },
            province: { required },
        };
    },
    emits: ['updateBreadcrumbs'],
    mounted() {
        this.$emit('updateBreadcrumbs', {label: 'Ajustes', to: compUrl, replace: true});
    },
    computed: {
        canSend() {
            return this.v$.name.$errors.length !== 0
                || this.v$.street.$errors.length !== 0
                || this.v$.number.$errors.length !== 0
                || this.v$.pc.$errors.length !== 0
                || this.v$.locality.$errors.length !== 0
                || this.v$.province.$errors.length !== 0
                || this.name.length === 0
                || this.street.length === 0
                || this.number.length === 0
                || this.pc.length !== 5
                || this.locality.length === 0
                || this.province.length === 0
                || this.working;
        }
    },
    methods: {
        fileChanged(event) {
            this.selectedFile = event.files[0];
            this.bg = this.selectedFile.name;
        },
        fileChanged2(event) {
            this.selectedFile2 = event.files[0];
            this.appbg = this.selectedFile2.name;
        },
        updateEstate() {
            if (!this.working) {
                this.working = true;
                this.updateError = null;
                this.success = false;
                let fd = new FormData();
                fd.append('name', this.name);
                fd.append('street', this.street);
                fd.append('number', this.number);
                fd.append('other', this.other);
                fd.append('pc', this.pc);
                fd.append('locality', this.locality);
                fd.append('province', this.province);
                fd.append('button_type', this.buttonType);

                if (this.selectedFile) {
                    fd.append('login_bg_file', this.selectedFile);
                }
                if (this.selectedFile2) {
                    fd.append('app_bg_file', this.selectedFile2);
                }

                this.estateService.updateEstate(
                    this.$appState.estate.id,
                    fd
                ).then(response => {
                    this.working = false;

                    if (response.data.estate) {
                        this.success = true;
                        this.$appState.estate = response.data.estate;
                    } else {
                        this.updateError = 'ERROR U-02: Ha ocurrido un error al actualizar los datos';
                    }
                }).catch(error => {
                    console.log(error);
                    this.errorReportService.sendReport(
                        this.$appState.visitorId,
                        window.navigator.userAgent,
                        this.$appState.estate.id,
                        this.errorReportService.getRequestData(error.request, {
                            name: this.name,
                            street: this.street,
                            number: this.number,
                            other: this.other,
                            pc: this.pc,
                            locality: this.locality,
                            province: this.province,
                            button_type: this.button_type,
                            login_bg_file: this.selectedFile?.name ?? '',
                            login_bg_file_size: this.selectedFile?.size ?? '',
                            app_bg_file: this.selectedFile2?.name ?? '',
                            app_bg_file_size: this.selectedFile2?.size ?? '',
                        }),
                        this.errorReportService.getErrorData(error.response),
                    );
                    this.working = false;
                    this.updateError = 'ERROR U-01: Ha ocurrido un error al actualizar los datos';
                });
            }
        }
    }
}
</script>

<style scoped lang="scss">
</style>
